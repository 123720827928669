import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetGlobalSmallSpotlightTitlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	first: Types.Scalars['Int']['input'];
	filter?: Types.InputMaybe<Types.TitleFilter>;
	platform: Types.Platform;
	language: Types.Scalars['Language']['input'];
	date?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;

export type GetGlobalSmallSpotlightTitlesQuery = {
	__typename?: 'Query';
	newTitles: {
		__typename?: 'NewTitlesConnection';
		edges?: Array<{
			__typename?: 'NewTitlesEdge';
			node:
				| { __typename?: 'Movie' }
				| {
						__typename?: 'Season';
						show: {
							__typename?: 'Show';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							watchNowOffer?: {
								__typename?: 'Offer';
								package: { __typename?: 'Package'; id: string; packageId: number; clearName: string };
							} | null;
							content: {
								__typename?: 'ShowContent';
								fullPath: string;
								title: string;
								shortDescription?: string | null;
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
						};
				  };
		}> | null;
	};
};

export const GetGlobalSmallSpotlightTitlesDocument = gql`
	query GetGlobalSmallSpotlightTitles(
		$country: Country!
		$first: Int!
		$filter: TitleFilter
		$platform: Platform!
		$language: Language!
		$date: Date
	) {
		newTitles(country: $country, first: $first, filter: $filter, date: $date) {
			edges {
				node {
					... on Season {
						show {
							id
							objectId
							objectType
							watchNowOffer(country: $country, platform: $platform) {
								package {
									id
									packageId
									clearName
								}
							}
							content(country: $country, language: $language) {
								fullPath
								backdrops {
									backdropUrl
								}
								title
								shortDescription
							}
						}
					}
				}
			}
		}
	}
`;
